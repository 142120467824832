import * as Sentry from '@sentry/react'
import { pollCondition } from 'utils/polling'

const setSessionURL = () => {
  window.LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL)
    })
  })
}

export const initializeSentry = pack => {
  if (process.env.SENTRY_DSN) {
    const herokuVersion = window.herokuReleaseVersion.replace(/^v/, '')
    const herokuCommit = window.herokuSlugCommit
    const herokuAppName = process.env.HEROKU_APP_NAME

    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.HEROKU_APP_NAME || process.env.RAILS_ENV,
      release: `${herokuAppName}@${herokuVersion}+${herokuCommit}`,
      initialScope: (scope) => {
        scope.setTags({ 'runtime.name': 'javascript', pack: pack })
        scope.setContext('runtime', { name: 'javascript' })
        return scope
      },
    })

    pollCondition(1000, () => !!window.LogRocket, setSessionURL, 10000)
  }
}
