const { appendOptions } = require('./utils')

export const borrowers = {
  maxwellRoot: () => '/',
  root: ({ id }) => `/borrowers/${id}/`,
  profile: ({ userId }) => `/borrowers/${userId}/profile`,
  sessions: { destroy: () => '/sign_out' },
  filefetch: {
    connections: {
      new: options => appendOptions('/filefetch/connections/new', options),
      import: ({ id }) => `/filefetch/connections/${id}/import`,
      interactions: ({ id }) => `/filefetch/connections/${id}/interactions`,
    },
  },
  connections: {
    base: ({ borrowerId }, options) => appendOptions(`/borrowers/${borrowerId}/connections/`, options),
    manageAccounts: {
      new: ({ borrowerId, id }, options) => {
        return appendOptions(`/borrowers/${borrowerId}/connections/${id}/manage_accounts/new`, options)
      },
    },
  },
  loanFile: {
    show: ({ borrowerId, id }) => `/borrowers/${borrowerId}/loan_files/${id}`,
    create: ({ borrowerId }, options) => {
      return appendOptions(`/borrowers/${borrowerId}/loan_files`, options)
    },
    attachments: {
      show: ({ borrowerId, attachmentId, loanFileId }, options) => {
        return appendOptions(`/borrowers/${borrowerId}/loan_files/${loanFileId}/attachments/${attachmentId}`, options)
      },
    },
    disclosuresTasks: {
      show: ({ borrowerId, disclosuresTaskId, loanFileId }) => {
        return `/borrowers/${borrowerId}/loan_files/${loanFileId}/disclosures_tasks/${disclosuresTaskId}`
      },
      idpSession: ({ dest }) => {
        return `/saml_idp_sessions?dest=${dest}`
      },
    },
  },
  employmentIncomeVerificationTask: {
    show: ({
      borrowerId,
      taskId,
      requestId,
    }) => `/borrowers/${borrowerId}/employment_income_verifications/tasks/${taskId}/researched_requests/${requestId}`,
    signAuthorization: ({
      borrowerId,
      taskId,
      requestId,
    }) => `/borrowers/${
      borrowerId
    }/employment_income_verifications/tasks/${taskId}/researched_requests/${requestId}/sign_authorizations`,
  },
  insuranceTasks: { show: ({ borrowerId, id }) => `/borrowers/${borrowerId}/insurance_tasks/${id}` },
  assetVerificationTasks: { show: ({ borrowerId, id }) => `/borrowers/${borrowerId}/asset_verification_tasks/${id}` },
  loanApps: { show: ({ borrowerId, id }) => `/borrowers/${borrowerId}/loan_apps/${id}` },
  v2LoanApps: {
    show: id => `/loan_applications/${id}/sections/0`,
    review: id => `/loan_applications/${id}/review`,
    quickApply: id => `/loan_applications/${id}/quick_apply/authenticate`,
    borrowers: id => `/loan_applications/${id}/borrowers`,
  },
}
