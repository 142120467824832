import { mapValues } from 'lodash'
// eslint-disable-next-line import/named
import { DEFAULT_DASHBOARD_METADATA } from '../constants'

export function createAction(type, wrapper = null) {
  return (payload) => {
    if (wrapper != null) {
      return ({ type, payload: wrapper(payload) })
    } else {
      return ({ type, payload })
    }
  }
}

export function createActionsFromActionTypes(actionTypes) {
  return mapValues(actionTypes, actionType => createAction(actionType))
}

// deprecated
export function fetchDashboardMetadataForRole() {
  return DEFAULT_DASHBOARD_METADATA
}
