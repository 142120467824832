/* eslint-disable max-len */
export * from 'v2/constants/DefaultDashboardMetadata'
export * from 'v2/constants/ProcessorDashboardMetadata'

export const BASE_API_URL = '/api'
export const FILETHIS = '/filethis'
export const FILETHIS_API_URL = `${BASE_API_URL}${FILETHIS}`
export const INSURE_DOCS_VENDOR_PNG_URL = 'https://s3-us-west-2.amazonaws.com/himaxwell/static/matic-agencies.png'
export const MATIC_LOGO_URL = 'https://himaxwell.s3-us-west-2.amazonaws.com/static/matic-logo.svg'
export const MATIC_TERMS = 'https://matic.com/terms'
export const MATIC_PRIVACY_POLICY = 'https://matic.com/policy'
export const DEFAULT_PROFILE_IMAGE_SRC = '/users/user-default.png'
export const DEFAULT_FILE_PREVIEW_IMAGE_SRC = 'https://s3-us-west-2.amazonaws.com/himaxwell/static/maxwell_file_preview_logo.png'
export const BORROWER_HELPDESK_URL = 'https://knowledge.himaxwell.com/175326-for-borrowers'

export const LENDER_PIPELINE_FUZZY_SEARCH_OPTIONS = {
  findAllMatches: true,
  includeMatches: true,
  includeScore: true,
  minMatchCharLength: 2,
  shouldSort: true,
  threshold: 0.4,
  keys: [
    'borrowerName',
    'realtorName',
    {
      name: 'externalLoanNumber',
      weight: 2,
    },
  ],
}

export const LOAN_MANAGER_LOAN_FILE_FUZZY_SEARCH_OPT = {
  shouldSort: false,
  findAllMatches: true,
  threshold: 0.4,
  minMatchCharLength: 2,
  keys: ['id', 'borrowerName'],
}

export const TEAM_FUZZY_SEARCH_OPT = {
  shouldSort: false,
  findAllMatches: true,
  threshold: 0.4,
  minMatchCharLength: 2,
  keys: ['accountName'],
}

export const OLD_TEAM_FUZZY_SEARCH_OPT = {
  caseSensitive: false,
  shouldSort: false,
  findAllMatches: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['accountName'],
}

export const FILETHIS_INSTITUTION_SEARCH_OPT = {
  shouldSort: false,
  findAllMatches: true,
  threshold: 0.4,
  minMatchCharLength: 2,
  keys: ['name'],
}

export const APP_CONSTANTS = {
  ACCOUNTS_URL: `${FILETHIS_API_URL}/accounts`,
  ACCOUNT_CONNECTIONS_URL: `${FILETHIS_API_URL}/account_connections`,
  CONNECTIONS_URL: `${FILETHIS_API_URL}/connections`,
  DESTINATIONS_URL: `${FILETHIS_API_URL}/destinations`,
  DOCUMENTS_URL: `${FILETHIS_API_URL}/documents`,
  INTERACTIONS_URL: `${FILETHIS_API_URL}/interactions`,
  SOURCES_URL: `${FILETHIS_API_URL}/sources`,
  PERIODS_URL: `${FILETHIS_API_URL}/periods`,
  CLOSE_EXTERNAL_ACCOUNTS: `${FILETHIS_API_URL}/close_external_accounts`,
  EVENTS_URL: `${FILETHIS_API_URL}/events`,
  ERROR_TEXT: 'Something went wrong. Please try again.',
}

export const ALLOW_LIST_DOCUMENTS_EXTENSIONS = [
  'doc',
  'docx',
  'pdf',
  'jpeg',
  'jpg',
  'png',
  'svg',
  'txt',
  'zip',
  'xlsx',
  'pptx',
  'heic',
  'heif',
]

export const PREAPPROVAL_MERGE_TAGS = {
  trigger: '{',
  tags: [
    { name: 'BORROWER_NAME', tag: '{BORROWER_NAME}' },
    { name: 'COBORROWER_NAME', tag: '{COBORROWER_NAME}' },
    { name: 'CREATION_DATE', tag: '{CREATION_DATE}' },
    { name: 'EXPIRATION_DATE', tag: '{EXPIRATION_DATE}' },
    { name: 'INTEREST_RATE', tag: '{INTEREST_RATE}' },
    { name: 'LOAN_TYPE', tag: '{LOAN_TYPE}' },
    { name: 'MAX APPROVED_AMOUNT', tag: '{MAX_APPROVED_AMOUNT}' },
    { name: 'PROPERTY_TYPE', tag: '{PROPERTY_TYPE}' },
    { name: 'REALTOR_NAME', tag: '{REALTOR_NAME}' },
    { name: 'PROPERTY_ADDRESS', tag: '{PROPERTY_ADDRESS}' },
    { name: 'TERM', tag: '{TERM}' },
  ],
}

export const PROPERTY_TYPES = [
  { text: 'Single family', value: 'single_family' },
  { text: 'Condominium', value: 'condominium' },
  { text: 'Townhouse', value: 'town_house' },
  { text: 'Multifamily 2-units', value: 'multi_family_two_units' },
  { text: 'Multifamily 3-4 units', value: 'multi_family_three_to_four_units' },
  { text: 'Manufactured Home', value: 'manufactured_home' },
  { text: 'Co-operative', value: 'co_operative' },
  { text: 'Planned Unit Development', value: 'planned_unit_development' },
  { text: 'Other', value: 'other' },
  { text: "I'm not sure", value: 'not_sure' },
]

export const MAX_32_BIT_INTEGER = 2147483647

export const APPLY_ID_OBLIGATION_FREQUENCY = {
  WEEKLY: 'WEEKLY',
  BIWEEKLY: 'BIWEEKLY',
  SEMIMONTHLY: 'SEMIMONTHLY',
  MONTHLY: 'MONTHLY',
  BIMONTHLY: 'BIMONTHLY',
  QUARTERLY: 'QUARTERLY',
  TRIENNIALLY: 'TRIENNIALLY',
  SEMIANNUALLY: 'SEMIANNUALLY',
  ANNUALY: 'ANNUALY',
}
