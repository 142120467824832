/* eslint-disable max-len */

import { appendOptions } from './utils'

export const api = {
  twoFactorAuths: {
    toggle: () => '/api/v1/two_factor_auths/toggle',
    update: () => '/api/v1/two_factor_auths/update',
    send_otp: () => '/api/v1/two_factor_auths/send_otp',
    resend_otp: () => '/api/v1/two_factor_auths/resend_otp',
    confirm_otp: () => '/api/v1/two_factor_auths/confirm_otp',
  },
  passwords: {
    update: () => '/api/v1/passwords',
    recover: () => '/api/v1/passwords/recover',
  },
  downloads: { index: ({ id }) => `/api/v1/loan_files/${id}/downloads` },
  log: () => '/api/v1/log',
  lendingQb: {
    sendDocuments: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/lending_qb_edocs`,
    syncLoanApp: {
      create: (
        { loanFileId, loanAppId }
      ) => `/api/v1/loan_files/${loanFileId}/loan_apps/${loanAppId}/lending_qb/sync_loan_app`,
      resync: (
        { loanFileId, loanAppId }
      ) => `/api/v1/loan_files/${loanFileId}/loan_apps/${loanAppId}/lending_qb/sync_loan_app/resync`,
    },
    validateLoanNumber: {
      show: ({
        loanFileId,
        id,
      }) => `/api/v1/loan_files/${loanFileId}/lending_qb_validate_loan_number/${id}`,
    },
    importExternalLoan: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/lending_qb/import_external_loan`,
  },
  loss: {
    create: () => '/api/v1/loss/create_loan_file',
    sendDocuments: () => '/api/v1/loss/send_documents',
    associateLoan: () => '/api/v1/loss/associate_loan',
    importLoan: () => '/api/v1/loss/import_loan',
  },
  encompass: { disclosures: { show: ({ id, taskId }) => `/api/v1/loan_files/${id}/disclosures_tasks/${taskId}` } },
  loanApp: {
    templates: {
      index: () => '/api/v1/loan_apps/templates',
      create: () => '/api/v1/loan_apps/templates',
      update: ({ id }) => `/api/v1/loan_apps/templates/${id}`,
      clone: ({ id }) => `/api/v1/loan_apps/templates/${id}/clone`,
      show: ({ id }) => `/api/v1/loan_apps/templates/${id}`,
      destroy: ({ templateId }) => `/api/v1/loan_apps/templates/${templateId}`,
      lockAllQuestions: (id, options) => appendOptions(`/api/v1/loan_apps/templates/${id}/lockable`, options),
      requireAllQuestions: (id, options) => appendOptions(`/api/v1/loan_apps/templates/${id}/requireable`, options),
    },
    templateSections: {
      index: () => '/api/v1/loan_apps/template_sections',
      create: () => '/api/v1/loan_apps/template_sections',
      update: ({ id }) => `/api/v1/loan_apps/template_sections/${id}`,
    },
    templateQuestions: {
      index: () => '/api/v1/loan_apps/template_questions',
      create: () => '/api/v1/loan_apps/template_questions',
      update: ({ id }) => `/api/v1/loan_apps/template_questions/${id}`,
      delete: ({ id }) => `/api/v1/loan_apps/template_questions/${id}`,
    },
    loanApp: {
      new: options => appendOptions('/api/v1/loan_apps/loan_apps/new', options),
      create: options => appendOptions('/api/v1/loan_apps/loan_apps', options),
      update: ({ id }) => `/api/v1/loan_apps/loan_apps/${id}`,
      show: ({ id }) => `/api/v1/loan_apps/loan_apps/${id}`,
      destroy: ({ id }, options) => appendOptions(`/api/v1/loan_apps/loan_apps/${id}`, options),
      submit: ({ id }, options) => appendOptions(`/api/v1/loan_apps/loan_apps/${id}/submit`, options),
      syncResponses: ({ id }) => `/api/v1/loan_apps/loan_apps/${id}/sync_responses`,
    },
  },
  filethis: {
    accounts: { create: () => '/api/v1/filethis/accounts' },
    connections: {
      index: () => '/api/v1/filethis/connections',
      show: ({ id }) => `/api/v1/filethis/connections/${id}`,
      create: () => '/api/v1/filethis/connections',
      destroy: ({ id }) => `/api/v1/filethis/connections/${id}`,
      importStatements:
        ({ id }, options) => appendOptions(`/api/filethis/account_connections/${id}/import_statements`, options),
    },
    accountConnections: { create: () => '/api/v1/filethis/account_connections' },
    interactions: {
      index: () => '/api/v1/filethis/interactions',
      create: () => '/api/v1/filethis/interactions',
    },
    periods: { show: ({ id }) => `/api/v1/filethis/periods/${id}` },
    sources: { index: () => '/api/v1/filethis/sources' },
  },
  loanFileRealtor: { destroy: ({ loanFileId, realtorId }) => `/api/v1/loan_files/${loanFileId}/realtors/${realtorId}` },
  loanFiles: {
    create: () => '/api/v1/loan_files',
    show: ({ id }) => `/api/v1/loan_files/${id}`,
    update: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}`,
    archived: () => '/api/v1/loan_files/archived',
    closed: () => '/api/v1/loan_files/closed',
    active: () => '/api/v1/loan_files/active',
    reassignments: ({ loanFileId }) => {
      return `/api/v1/loan_files/${loanFileId}/reassignments`
    },
    fetchItpAcceptedAt: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/fetch_itp_accepted_at`,
    participants: { create: () => '/api/v1/participants' },
    propertyAddress: { show: ({ id }) => `/api/v1/loan_files/${id}/property_address` },
    quoteRequests: {
      create: ({
        loanFileId,
        taskId,
      }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}/quote_requests/`,
    },
    loanNoteDocuments: {
      create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/loan_note_documents`,
      index: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/loan_note_documents`,
    },
    paymentTasks: {
      create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/payment_tasks`,
      update: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/payment_tasks/${taskId}`,
      destroy: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/payment_tasks/${taskId}`,
    },
    quickPricer: {
      scenarios: {
        index: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/quick_pricer/scenarios`,
        create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/quick_pricer/scenarios`,
        update: ({ loanFileId, scenarioId }) => `/api/v1/loan_files/${loanFileId}/quick_pricer/scenarios/${scenarioId}`,
        destroy: ({ loanFileId, scenarioId }) => `/api/v1/loan_files/${loanFileId}/quick_pricer/scenarios/${scenarioId}`,
        rerunProductSearch: ({
          loanFileId,
          scenarioId,
        }) => `/api/v1/loan_files/${loanFileId}/quick_pricer/scenarios/${scenarioId}/rerun_product_search`,
      },
    },
    attachments: {
      index: ({ loanFileId }) => {
        return `/api/v1/loan_files/${loanFileId}/attachments`
      },
      bulkDelete: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/attachments/bulk_delete`,
      update: ({ loanFileId, attachmentId }) => `/api/v1/loan_files/${loanFileId}/attachments/${attachmentId}`,
      rejected: ({ loanFileId, attachmentId }) => {
        return `/api/v1/loan_files/${loanFileId}/attachments/${attachmentId}/reject`
      },
      accepted: ({ loanFileId, attachmentId }) => {
        return `/api/v1/loan_files/${loanFileId}/attachments/${attachmentId}/accept`
      },
      received: ({ loanFileId, attachmentId }) => {
        return `/api/v1/loan_files/${loanFileId}/attachments/${attachmentId}/received`
      },
      viewed: ({ loanFileId, attachmentId }) => {
        return `/api/v1/loan_files/${loanFileId}/attachments/${attachmentId}/viewed`
      },
      newVersion: {
        create: ({ loanFileId, attachmentId }) => {
          return `/api/v1/loan_files/${loanFileId}/attachments/${attachmentId}/new_version`
        },
      },
    },
    screenShare: { watchUrl: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/screen_share/watch_url` },
  },
  credit: {
    exports: {
      retry: ({ creditExportId }) => `/api/v1/credit_exports/${creditExportId}`,
      sync: ({ creditExportId }) => `/api/v1/credit_exports/${creditExportId}`,
    },
  },
  loanFile: {
    assetVerificationTasks: {
      create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/asset_verifications/tasks`,
      show: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/asset_verifications/tasks/${id}`,
      destroy: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/asset_verifications/tasks/${id}`,
      update: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/asset_verifications/tasks/${id}`,
      complete: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/asset_verifications/tasks/${taskId}/complete`,
    },
    assetVerificationReports: {
      create: ({
        loanFileId,
        taskId,
      }) => `/api/v1/loan_files/${loanFileId}/asset_verifications/tasks/${taskId}/reports`,
    },
    aus: {
      caseFiles: { index: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/aus/casefiles` },
      decisions: {
        create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/aus/decisions`,
        new: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/aus/decisions/new`,
      },
    },
    credits: {
      tasks: {
        create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/credit_tasks`,
        show: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/credit_tasks/${id}`,
        update: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/credit_tasks/${id}`,
        delete: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/credit_tasks/${id}`,
      },
      lenderCreditDetail: { update: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/lender_credit_detail/${id}` },
    },
    defaultTemplates: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/default_templates`,
    documentSearch: ({ id }) => `/api/v1/loan_files/${id}/document_search`,
    employmentIncomeVerificationTask: {
      create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/employment_income_verifications/tasks`,
      destroy: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/employment_income_verifications/tasks/${taskId}`,
      instantRequest: (
        { loanFileId, taskId },
      ) => `/api/v1/loan_files/${loanFileId}/employment_income_verifications/tasks/${taskId}/instant_requests`,
      researchedRequests: {
        show: (
          { loanFileId, taskId, requestId },
        ) => `/api/v1/loan_files/${loanFileId}/employment_income_verifications/tasks/${taskId}/researched_requests/${requestId}`,
        create: (
          { loanFileId, taskId },
        ) => `/api/v1/loan_files/${loanFileId}/employment_income_verifications/tasks/${taskId}/researched_requests`,
        update: (
          { loanFileId, taskId, requestId },
        ) => `/api/v1/loan_files/${loanFileId}/employment_income_verifications/tasks/${taskId}/researched_requests/${requestId}`,
      },
    },
    finicity: {
      connectUrl: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}/finicity_connect_url`,
      connectFixUrl: ({ loanFileId, taskId, customerInstitutionLoginId }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}/finicity_connect_fix_url?finicity_customer_institution_login_id=${customerInstitutionLoginId}`,
      importStatements: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}/finicity_import_statements`,
    },
    insuranceTasks: {
      create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/insurance_tasks`,
      show: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/insurance_tasks/${id}`,
    },
    loanProfile: { update: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/loan_profile` },
    messenger: { toggle: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/toggle` },
    plaidDetailsItems: {
      create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/plaid_details_items`,
      update: ({ loanFileId, itemId }) => `/api/v1/loan_files/${loanFileId}/plaid_details_items/${itemId}`,
    },
    preApprovalLetters: {
      tasks: {
        index: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks`,
        create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks`,
        show: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks/${id}`,
        update: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks/${id}`,
        delete: ({ loanFileId, id }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks/${id}`,
        saveAndPreview: ({
          loanFileId,
          id,
        }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks/${id}/save_and_preview`,
        generateNewLetter: ({
          loanFileId,
          id,
        }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks/${id}/generate_new_letter`,
        fetchLetters: ({
          loanFileId,
          id,
        }) => `/api/v1/loan_files/${loanFileId}/pre_approval_letter_tasks/${id}/fetch_letters`,
      },
    },
    status: ({ id }) => `/api/v1/loan_files/${id}/status`,
    statusEmail: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/status_email`,
  },
  loanFileParticipants: {
    index: ({ id }) => `/api/v1/loan_files/${id}/loan_file_participants`,
    delete: ({ id, participantId }, options) => {
      return appendOptions(`/api/v1/loan_files/${id}/loan_file_participants/${participantId}`, options)
    },
  },
  bulkLoanFileParticipants: {
    create: ({ id }, options) => {
      return appendOptions(`/api/v1/loan_files/${id}/bulk_loan_file_participants`, options)
    },
  },
  realtors: { loan_officers: { show: ({ id }) => `/api/v1/loan_officers/${id}` } },
  organization: {
    show: ({ organizationId }) => `/api/v1/organizations/${organizationId}`,
    update: ({ organizationId }) => `/api/v1/organizations/${organizationId}`,
    loanFiles: { destroy: ({ organizationId, loanFileId }) => `/api/v1/organizations/${organizationId}/loan_files/${loanFileId}` },
    loanOfficers: { create: ({ organizationId }) => `/api/v1/organizations/${organizationId}/loan_officers` },
    mpayActivation: ({ organizationId }) => `/api/v1/organizations/${organizationId}/mpay_activation`,
    activateMpay: ({ organizationId }) => `/api/v1/organizations/${organizationId}/mpay_activation/activate`,
    organizations: ({ organizationId }) => `/api/v1/organizations/${organizationId}/organizations`,
    teams: {
      index: ({ organizationId }) => `/api/v1/organizations/${organizationId}/teams`,
      create: ({ organizationId }) => `/api/v1/organizations/${organizationId}/teams`,
    },
    users: {
      index: ({ organizationId }) => `/api/v1/organizations/${organizationId}/users`,
      show: ({ organizationId, userId }) => `/api/v1/organizations/${organizationId}/users/${userId}`,
      create: ({ organizationId }) => `/api/v1/organizations/${organizationId}/users`,
      update: ({ organizationId, userId }) => `/api/v1/organizations/${organizationId}/users/${userId}`,
      delete: ({ organizationId, userId }) => `/api/v1/organizations/${organizationId}/users/${userId}`,
    },
    paymentTasks: {
      index: ({ organizationId }) => `/api/v1/organizations/${organizationId}/payment_tasks`,
      refund: ({ organizationId, paymentTaskId }) => `/api/v1/organizations/${organizationId}/payment_tasks/${paymentTaskId}/refund`,
    },
    eConsentSetting: {
      show: ({ organizationId }) => `/api/v1/organizations/${organizationId}/e_consent_setting`,
      update: ({ organizationId }) => `/api/v1/organizations/${organizationId}/e_consent_setting`,
    },
    mpaySetting: {
      show: ({ organizationId }) => `/api/v1/organizations/${organizationId}/mpay_settings`,
      update: ({ organizationId }) => `/api/v1/organizations/${organizationId}/mpay_settings`,
    },
  },
  organizations: {
    emailSignatures: {
      show: ({ organizationId }) => `/api/v1/organizations/${organizationId}/email_signatures`,
      update: ({ organizationId }, options) => {
        return appendOptions(`/api/v1/organizations/${organizationId}/email_signatures`, options)
      },
      deploy: { create: ({ organizationId }) => `/api/v1/organizations/${organizationId}/email_signatures/deploy` },
    },
    emailTemplates: { deploy: { create: ({ organizationId }) => `/api/v1/organizations/${organizationId}/email_templates/deploy` } },
    textMessageSettings: {
      index: ({ organizationId }) => `/api/v1/organizations/${organizationId}/text_message_settings`,
      deploys: { create: ({ organizationId }) => `/api/v1/organizations/${organizationId}/text_message_settings/deploy` },
    },
    preApprovalLetterTemplates: {
      index: ({ organizationId }) => `/api/v1/organizations/${organizationId}/pre_approval_letter_templates`,
      deployAll: ({ organizationId }) => `/api/v1/organizations/${organizationId}/pre_approval_letter_templates/deploy_all`,
      create: ({ organizationId }) => `/api/v1/organizations/${organizationId}/pre_approval_letter_templates`,
      lock: ({ id, organizationId }) => `/api/v1/organizations/${organizationId}/pre_approval_letter_templates/${id}/lock`,
      show: ({ id, organizationId }) => `/api/v1/organizations/${organizationId}/pre_approval_letter_templates/${id}`,
      update: ({ id, organizationId }) => `/api/v1/organizations/${organizationId}/pre_approval_letter_templates/${id}`,
      destroy: ({ id, organizationId }) => `/api/v1/organizations/${organizationId}/pre_approval_letter_templates/${id}`,
    },
  },
  masterLoanFileTemplates: {
    index: () => '/api/v1/master_loan_file_templates/loan_file_templates',
    create: () => '/api/v1/master_loan_file_templates/loan_file_templates',
    update: ({ id }) => `/api/v1/master_loan_file_templates/loan_file_templates/${id}`,
    destroy: ({ id }) => `/api/v1/master_loan_file_templates/loan_file_templates/${id}`,
    clone: (options) => {
      return appendOptions('/api/v1/master_loan_file_templates/clone', options)
    },
    teamLock: () => '/api/v1/master_loan_file_templates/team_locks',
    lock: (options) => {
      return appendOptions('/api/v1/master_loan_file_templates/locks', options)
    },
    emailTemplates: {
      lock: ({ id, organizationId }) => `/api/v1/organizations/${organizationId}/email_templates/${id}/lock`,
      index: ({ organizationId }) => `/api/v1/organizations/${organizationId}/email_templates`,
      update: ({ id, organizationId }) => `/api/v1/organizations/${organizationId}/email_templates/${id}`,
    },
    taskTemplates: {
      create: ({ loanFileTemplateId }) => `/api/v1/master_loan_file_templates/${loanFileTemplateId}/task_templates`,
      update: ({ loanFileTemplateId, id }) => `/api/v1/master_loan_file_templates/${loanFileTemplateId}/task_templates/${id}`,
      destroy: ({ loanFileTemplateId, id }) => `/api/v1/master_loan_file_templates/${loanFileTemplateId}/task_templates/${id}`,
    },
  },
  loanFileTemplates: {
    index: () => '/api/v1/loan_file_templates',
    create: () => '/api/v1/loan_file_templates',
    update: ({ id }) => `/api/v1/loan_file_templates/${id}`,

    taskTemplates: {
      create: ({ loanFileTemplateId }) => `/api/v1/loan_file_templates/${loanFileTemplateId}/task_templates`,
      update: ({ loanFileTemplateId, id }) => `/api/v1/loan_file_templates/${loanFileTemplateId}/task_templates/${id}`,
      destroy: ({ loanFileTemplateId, id }) => `/api/v1/loan_file_templates/${loanFileTemplateId}/task_templates/${id}`,
    },
  },
  messenger: {
    acknowledged: ({ id, communicationId }) => `/api/v1/loan_files/${id}/communications/${communicationId}/acknowledged`,
    communications: ({ id }) => `/api/v1/loan_files/${id}/communications`,
    communication: ({ id }) => `/api/v1/loan_files/${id}/communication`,
    attachments: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/communications`,
  },
  notifications: { base: { create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/notifications/base` } },
  signableDocuments: {
    show: ({ id, documentId }) => {
      return `/api/v1/loan_files/${id}/signable_documents/${documentId}`
    },
    create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/signable_documents`,
    update: ({ id, documentId }) => `/api/v1/loan_files/${id}/signable_documents/${documentId}`,
    destroy: ({ id, documentId }) => `/api/v1/loan_files/${id}/signable_documents/${documentId}`,
  },
  tasks: {
    index: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/tasks`,
    create: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/tasks`,
    show: ({ id, taskId }) => `/api/v1/loan_files/${id}/tasks/${taskId}`,
    update: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}`,
    destroy: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}`,
    incomplete: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/incomplete`,
    complete: ({ loanFileId }) => `/api/v1/loan_files/${loanFileId}/complete`,
  },
  disclosuresTasks: {
    update: ({ loanFileId, taskId, taskItemId }) => `/api/v1/loan_files/${loanFileId}/disclosures_tasks/${taskId}/disclosures_task_items/${taskItemId}`,
    destroy: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/disclosures_tasks/${taskId}/destroy`,
    ssoToken: ({ loanFileId, taskId, taskItemId }) => `/api/v1/loan_files/${loanFileId}/disclosures_tasks/${taskId}/disclosures_task_items/${taskItemId}/sso_token`,
  },
  documentGroups: { upload: ({ loanFileId, documentGroupId }) => `/api/v1/loan_files/${loanFileId}/document_groups/${documentGroupId}/documents` },
  task: {
    complete: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}/complete`,
    incomplete: ({ loanFileId, taskId }) => `/api/v1/loan_files/${loanFileId}/tasks/${taskId}/incomplete`,
  },
  teams: {
    show: ({ teamId }) => `/api/v1/teams/${teamId}`,
    update: ({ teamId }) => `/api/v1/teams/${teamId}`,
    loan_files: ({ teamId }) => `/api/v1/teams/${teamId}/loan_files`,
    preApprovalLetterTemplates: { index: ({ teamId }) => `/api/v1/teams/${teamId}/pre_approval_letter_templates` },
    eligibleLenders: ({ teamId }) => `/api/v1/teams/${teamId}/eligible_lenders`,
    eligiblePipelines: ({ teamId }) => `/api/v1/teams/${teamId}/eligible_pipelines`,
    reassignPipelines: ({ teamId }) => `/api/v1/teams/${teamId}/reassign_pipelines`,
    updateSelectedTeam: ({ teamId }) => `/api/v1/teams/${teamId}/update_selected_team`,
    destroyLoanFiles: ({ teamId }) => `/api/v1/teams/${teamId}/destroy_loan_files`,
    teamRoles: {
      index: ({ teamId }) => `/api/v1/teams/${teamId}/roles`,
      destroy: ({ teamId, teamRoleId }) => `/api/v1/teams/${teamId}/roles/${teamRoleId}`,
      destroyInBackground: () => '/api/v1/remove_pipelines',
      add: ({ teamId }) => `/api/v1/teams/${teamId}/roles`,
      addInBackground: () => '/api/v1/assign_team_pipelines',
    },
  },
  team: {
    update: ({ id }) => `/api/v1/teams/${id}`,
    optionsForSelect: () => '/api/v1/teams',
  },
  plaid: {
    linkToken: () => '/api/v1/plaid/link_token',
    balances: () => '/api/v1/plaid/balances',
  },
  admin: {
    importTeamsFromFile: () => '/api/v1/admin/import_teams_from_file',
    bulkCreateTeamRoles: () => '/api/v1/admin/team_roles/bulk_create',
    bulkDeleteTeamRoles: () => '/api/v1/admin/team_roles/bulk_delete',
    generateOrganizationImport: () => '/api/v1/admin/org_import/generate_import',
    executeOrganizationImport: ({ id }) => `/api/v1/admin/org_import/execute_import/${id}`,
    generateOrganizationTreeData: ({ organizationId }) => `/api/v1/admin/org_tree_view/generate_organization_tree_data/${organizationId}`,
    lendingQbAudits: { create: () => '/api/v1/admin/lending_qb_audits' },
  },
  lenders: {
    lenderSettings: { create: ({ lenderId }) => `/api/v1/lenders/${lenderId}/lender_settings` },
    profile: {
      show: () => '/api/v1/lenders/profile',
      update: () => '/api/v1/lenders/profile',
    },
    teamRoles: { index: ({ lenderId }) => `/api/v1/lenders/${lenderId}/team_roles` },
    invite: ({ lenderId }) => `/api/v1/lenders/${lenderId}/invite`,
    quickPricer: {
      statesAndCounties: { index: ({ lenderId }) => `/api/v1/lenders/${lenderId}/quick_pricer/states_and_counties` },
      products: { create: ({ lenderId }) => `/api/v1/lenders/${lenderId}/quick_pricer/products` },
    },
    docuSign: {
      new: () => '/auth/docusign',
      delete: ({ teamId }) => (`/docusigns/${teamId}`),
      logout: ({ teamId }) => (`/docusigns/${teamId}/docusign_logout`),
    },
  },
  paymentIntents: {
    create: () => '/api/v1/payments/intents',
    update: ({ taskId }) => `/api/v1/payments/intents/${taskId}`,
  },
  quickPricer: {
    scenarios: {
      index: () => '/api/v1/quick_pricer/scenarios',
      create: () => '/api/v1/quick_pricer/scenarios',
      update: ({ scenarioId }) => `/api/v1/quick_pricer/scenarios/${scenarioId}`,
      destroy: ({ scenarioId }) => `/api/v1/quick_pricer/scenarios/${scenarioId}`,
      rerunProductSearch: ({ scenarioId }) => `/api/v1/quick_pricer/scenarios/${scenarioId}/rerun_product_search`,
      assign: () => '/api/v1/quick_pricer/scenarios/assign',
    },
  },
}
