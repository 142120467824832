import toastr from 'toastr'

toastr.options = {
  closeButton: true,
  debug: false,
  escapeHtml: true,
  extendedTimeOut: '1500',
  hideDuration: '1500',
  hideEasing: 'linear',
  hideMethod: 'fadeOut',
  newestOnTop: true,
  onclick: null,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  progressBar: true,
  showDuration: '750',
  showEasing: 'swing',
  showMethod: 'fadeIn',
  timeOut: '1500',
}

export default toastr;
