import frontendRoutes from './frontendRoutes'
import { borrowers } from './borrowerRoutes'
import { lenders } from './lenderRoutes'
import { api } from './apiRoutes'
import { appendOptions } from './utils'

const organizations = {
  preApprovalLetterTemplates: {
    show: ({ organizationId, id }) => `/organizations/${organizationId}/pre_approval_letter_templates/${id}`,
    preview: ({ organizationId, id }) => `/organizations/${organizationId}/pre_approval_letter_templates/${id}/preview`,
  },
}

const Route = {
  borrowers,
  lenders,
  organizations,
  api,
  appendOptions,
  frontendRoutes,
}

export default Route
