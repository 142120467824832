// import AT from '../actionTypes'
import v2ActionTypes from '../v2/actionTypes'

export default function pageInfo(state = {}, action) {
  const { type, payload } = action

  switch (type) {
    case v2ActionTypes.SET_USER_INFO:
      return {
        ...state,
        userInfo: payload,
      }
    case v2ActionTypes.RESET_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unread_messages_count: 0,
      }
    case v2ActionTypes.SET_LOAN_FILE_ID:
      return {
        ...state,
        loan_file_id: payload,
      }
    default: {
      return state
    }
  }
}
