import { appendOptions } from './utils'

export const lenders = {
  root: () => '/loans',
  loanFile: {
    show: ({ slug, id }) => `/lenders/${slug}/loan_files/${id}`,
    new: ({ slug }) => `/lenders/${slug}/loan_files/new`,
    tasks: ({ slug, id }) => `/lenders/${slug}/loan_files/${id}?tasks=true`,
    taskEditor: ({ slug, id }) => `/lenders/${slug}/loan_files/${id}/task_editor`,
    emailEditor: ({ slug, id }) => `/lenders/${slug}/loan_files/${id}/email_editor`,
    templateSelect: ({ slug, id }) => `/lenders/${slug}/loan_files/${id}/template_select`,
    documents: {
      show: ({ slug, loanFileId, id }, options) => {
        return appendOptions(`/lenders/${slug}/loan_files/${loanFileId}/documents/${id}`, options)
      },
    },
    attachments: {
      show: ({ slug, loanFileId, attachmentId }, options) => {
        return appendOptions(`/lenders/${slug}/loan_files/${loanFileId}/attachments/${attachmentId}`, options)
      },
    },
    credits: { show: ({ slug, loanFileId, id }) => `/lenders/${slug}/loan_files/${loanFileId}/credits/${id}` },
    messenger: {
      downloadCommunications: ({
        lenderId,
        loanFileId,
      }) => `/lenders/${lenderId}/loan_files/${loanFileId}/download_communications`,
    },
    preApprovalLetters: {
      show: ({
        slug,
        loanFileId,
        taskId,
      }) => `/lenders/${slug}/loan_files/${loanFileId}/pre_approvals/${taskId}`,
    },
    aus: {
      caseFiles: {
        new: ({
          lenderId,
          loanFileId,
        }) => `/lenders/${lenderId}/loan_files/${loanFileId}/aus/casefiles/new`,
      },
    },
  },
  loanApp: {
    show: ({ slug, id, loanFileId }) => `/lenders/${slug}/loan_files/${loanFileId}/loan_apps/${id}`,
    download: ({ slug, id, loanFileId }) => `/lenders/${slug}/loan_files/${loanFileId}/loan_apps/${id}/download`,
    download34: ({
      slug,
      id,
      loanFileId,
    }) => `/lenders/${slug}/loan_files/${loanFileId}/loan_apps/${id}/download_mismo34.xml`,
    downloadMetadata: ({ slug, id, loanFileId }, options) => {
      return appendOptions(`/lenders/${slug}/loan_files/${loanFileId}/loan_apps/${id}/download_metadata.pdf`, options)
    },
    templates: { show: ({ slug, id }) => `/lenders/${slug}/loan_apps/templates/${id}` },
  },
  enhancedLoanApp: {
    show: loanAppId => `/loan_applications/${loanAppId}/sections/0`,
    review: loanAppId => `/loan_applications/${loanAppId}/review`,
    mismoFile: ({ loanFileId, loanAppId, slug }) => (
      `/lenders/${slug}/loan_files/${loanFileId}/loan_apps/${loanAppId}/mismo?download=true`
    ),
  },

  loanFileTemplates: {
    index: ({ slug }) => `/lenders/${slug}/loan_file_templates`,
    review: ({ slug, id }) => `/lenders/${slug}/loan_file_templates/${id}/review`,
  },

  templateManager: {
    loanFileTemplates: {
      show: ({ id, organizationId }) => `/organizations/${organizationId}/loan_file_template_manager/${id}`,
      review: ({
        id,
        organizationId,
      }) => (`/organizations/${organizationId}/loan_file_template_manager/${id}/manager_review`),
    },
  },
  quickPricer: { new: ({ slug }) => `/lenders/${slug}/quick_pricer/new` },
}
