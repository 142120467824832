import axios from 'axios'
import Route from 'Services/Route'
import { ConcurrencyManager } from 'axios-concurrency'
import { toastrErrors } from './error_utils'

export const apiClient = axios.create({
  transformResponse: [
    function (data) {
      try {
        return JSON.parse(data)
      } catch (Exception) {
        return data
      }
    },
  ],
})

export const api = (getState, extraHeaders) => {
  const { apiToken, baseUrl } = getState().pageInfo.userInfo
  let headers = {
    Authorization: `Token ${apiToken}`,
    baseUrl: baseUrl,
    Accept: 'application/vnd.himaxwell.com; version=1,application/json',
    timeout: 15000,
    common: { 'X-Key-Inflection': 'camel', 'X-Internal': 'true' },
  }
  headers = _.extend(headers, extraHeaders)
  apiClient.defaults.headers = headers
  return apiClient
}

export default api

export const apiWithoutInflections = (getState, extraHeaders) => {
  let headers = { common: { 'X-Internal': 'true' } }
  headers = _.extend(headers, extraHeaders)
  return api(getState, headers)
}

let apiConcurrentClient
export const apiWithConcurrency = (getState, extraHeaders) => {
  const MAX_CONCURRENT_REQUESTS = 5
  if (apiConcurrentClient === undefined) {
    apiConcurrentClient = api(getState, extraHeaders)
    ConcurrencyManager(apiConcurrentClient, MAX_CONCURRENT_REQUESTS)
  }
  return apiConcurrentClient
}

export const postRequest = async ({ getState, url, params, raiseError = false } = {}) => {
  try {
    return await api(getState).post(url, params)
  } catch (error) {
    console.info(error)
    const { data = {} } = error.response || {}
    const { errors } = data

    if (errors) {
      return toastrErrors(errors)
    }

    if (raiseError) {
      return error
    }
  }
}

export const putRequest = async ({ getState, url, params, raiseError = false } = {}) => {
  try {
    return await api(getState).put(url, params)
  } catch (error) {
    console.info(error)
    const { data = {} } = error.response || {}
    const { errors } = data

    if (errors) {
      return toastrErrors(errors)
    }

    if (raiseError) {
      return error
    }
  }
}

export const getRequest = async ({ getState, url, raiseError = false } = {}) => {
  try {
    return await api(getState).get(url)
  } catch (error) {
    console.info(error)
    const { data = {} } = error.response || {}
    const { errors } = data

    if (errors) {
      return toastrErrors(errors)
    }

    if (raiseError) {
      return error
    }
  }
}

export const signOut = async (getState) => {
  try {
    const { borrowers: { sessions: { destroy } } } = Route
    const { pageInfo } = getState()
    const { formInfo: { authenticityToken } } = pageInfo

    const bodyFormData = new FormData()
    bodyFormData.append('authenticity_token', authenticityToken)
    return await api(
      () => ({ pageInfo }),
      { 'Content-Type': 'multipart/form-data' }
    ).delete(
      destroy(),
      { data: bodyFormData }
    )
  } catch (error) {
    console.info(error)
    const { data = {} } = error.response || {}
    const { errors } = data

    if (errors) {
      return toastrErrors(errors)
    }
  }
}
