import mirrorCreator from 'mirror-creator'
import { createActionTypes } from './generateActionTypes'

// PreApproval Actions
export const preApprovalLetterTemplates = createActionTypes('preApprovalLetterTemplates',
  { also: ['deploying', 'showTemplateRenameForm'] })
export const preApprovalLetterTasks = createActionTypes('preApprovalLetterTasks',
  { also: ['setStagedLetters', 'addStagedLetter'] })

// Homeowners Insurance Actions (ie Matic)
export const insuranceTasks = createActionTypes('insuranceTasks', { also: ['setCurrentRecord'] })

// Asset Verification Actions (ie Plaid)
export const assetVerificationTasks = createActionTypes('assetVerificationTasks',
  { also: ['setCurrentRecord', 'showGenerateAssetReportModal'] })

// QuickPricer
export const quickPricerScenarios = createActionTypes('quickPricerScenarios', { also: ['updateFirstProduct'] })
export const quickPricerAccounts = createActionTypes('quickPricerAccounts')
export const optimalBlueStates = createActionTypes('optimalBlueStates', { also: ['setStateLoadingIncomplete'] })

// Desktop Underwriter
export const duCaseFiles = createActionTypes('duCaseFiles', {
  also: [
    'setCreatingCaseFile',
  ],
})

export const duDecisionTemplates = createActionTypes('duDecisionTemplates', {
  also: [
    'setResponse',
    'setErrors',
    'setControlInput',
  ],
})

// Disclosures Actions (ie Docutech/ Encompass)
const disclosuresTasksAddons = [
  'toggleEmbeddedDocutechModal',
  'setSsoToken',
]

export const disclosuresTasks = createActionTypes('disclosuresTasks', { also: disclosuresTasksAddons })

export const disclosuresTaskItems = createActionTypes('disclosuresTaskItems')

export const disclosureSelfService = createActionTypes('disclosureSelfService', { also: ['success'] })

// Employment & Income Verification Actions (ie VOE/VOI from Equifax)

const employmentIncomeVerificationTasksAddons = [
  'nextResearchedPosition',
  'toggleAdvancedOptions',
]

export const employmentIncomeVerificationTasks = createActionTypes(
  'employmentIncomeVerificationTasks',
  { also: employmentIncomeVerificationTasksAddons },
)

// Teams
export const teams = createActionTypes('teams')

// Maxwell Pay
export const paymentTasks = createActionTypes('paymentTasks', {
  also: [
    'toggleITPModal',
    'setITPSubmitting',
    'setITPFetching',
    'setPaymentIntentLoading',
    'setPaymentIntentUpdating',
    'setPaymentCompleted',
    'setPaymentClientSecret',
    'togglePaymentIntentModal',
    'setSelectedTaskId',
    'markAsComplete',
  ],
})
export const mpaySettings = createActionTypes('mpaySettings', {
  also: [
    'loading',
    'activation',
    'submitting',
    'showSubmitModal',
    'submittingPaymentPurposes',
    'requiredPaymentPurposes',
    'submittingEmail',
  ],
})

// Lender Settings
export const lenderProfile = createActionTypes('lenderProfile', {
  also: [
    'showConfirmModal',
    'toggleTwoFactorUpdating',
  ],
})
export const organizationSettings = createActionTypes('organizationSettings', { also: ['editing'] })
export const pipelineLoanFiles = createActionTypes('pipelineLoanFiles', {
  also: [
    'reassign',
    'setEligiblePipelines',
    'setTargetPipeline',
    'showDeleteModal',
    'pipelinesUpdatingInBackground',
    'loanFilesDeletingInBackground',
  ],
})
export const pipelinesSettings = createActionTypes('pipelinesSettings', { also: ['togglePanel'] })
export const pipelineUsers = createActionTypes('pipelineUsers', {
  also: [
    'setEligibleLenders',
    'setPipelineUsersFetching',
  ],
})
export const usersSettings = createActionTypes('usersSettings', {
  also: [
    'organizationsLoading',
    'sendingInvite',
    'setOrganizations',
    'showDeleteModal',
  ],
})

export const pipelineSettings = createActionTypes('pipelineSettings', {
  also: [
    'setPipelineId',
    'setLoanAppTemplates',
    'setPipeline',
  ],
})

export const paymentsHistory = createActionTypes('paymentsHistory', { also: ['showRefundModal'] })
export const eConsentSetting = createActionTypes('eConsentSetting')

// Love Our Loan Application
export const loanApplications = createActionTypes('loanApplications')
export const loanApplicationsTemplates = createActionTypes('loanApplicationsTemplates')
export const accountCreation = createActionTypes('accountCreation')
export const lossLoanNumber = createActionTypes('lossLoanNumber', {
  only: [
    'validateLossLoanNumber',
    'toggleLossLoanNumberEditModal',
  ],
})

// Credit Exports
export const creditExports = createActionTypes(
  'creditExports', { only: ['fetch', 'retry'] }
)
export const plaidLinkToken = createActionTypes('plaidLinkToken', { only: ['setStagedRecord', 'set'] })

export const screenShareVisitors = createActionTypes('screenShareVisitors', {})
export const borrowerNewLoanModal = createActionTypes('borrowerNewLoanModal', { only: ['show'] })

// OLD ACTION PATTERN - DO NOT ADD TO
export default mirrorCreator([
  'SET_ACTIVE_LOAN_FILES',
  'SET_CLOSED_LOAN_FILES',
  'SET_ARCHIVED_LOAN_FILES',
  'SET_ACTIVE_LOAN_FILE_TAB',
  'LOADING_ACTIVE_LOAN_FILES',
  'LOADING_LOAN_FILE',
  'LOADING_CLOSED_LOAN_FILES',
  'NEW_CLIENT_SUBMITTING',
  'SEND_LOAN_FILE_STATUS_EMAIL_SUCCESS',
  'CREATING_NEW_LOAN_FILE',
  'CREATING_NEW_TASK',
  'CREATING_NEW_LOAN_APP',
  'LOADING_ARCHIVED_LOAN_FILES',
  'SET_OPTIONS_FOR_TEAM_SELECT',
  'UPDATING_SELECTED_TEAM',
  'SET_SELECTED_TEAM',
  'SET_LOAN_FILE',
  'UPDATE_LOAN_FILE',
  'SET_LOAN_FILE_STAGED_RECORD',
  'SET_LOAN_FILE_STAGED_RECORD_ATTRIBUTES',
  'REASSIGN_LOAN_FILE',
  'SET_V2_DOCUMENTS',
  'SET_V2_DOCUMENT',
  'TOGGLE_COMPLETE_TASK_MODAL',
  'TOGGLE_COMPLETE_TASK_MODAL_PROCESSING',
  'TOGGLE_ALL_DOCUMENTS',
  'TOGGLE_DOCUMENT',
  'TOGGLE_DELETE_BULK_FILE_MODAL',
  'TOGGLE_DELETE_SINGLE_FILE_MODAL',
  'SET_SELECTED_DOCUMENT_ID',
  'SET_LOADING_DOCUMENTS',
  'SYNCING_WITH_LENDING_QB',
  'SYNCING_WITH_LOSS',
  'UPDATE_SELECTED_TASK',
  'CREATE_TASK',
  'SET_NOTE_PREVIEW',
  'SET_COMPLETE_TASKS',
  'SET_INCOMPLETE_TASKS',
  'UPDATE_DOCUMENT_ON_INCOMPLETE_TASK',
  'UPDATE_DOCUMENT_ON_COMPLETE_TASK',
  'SET_SELECTED_TASK',
  'TOGGLE_CREATE_TASK_MODAL',
  'TOGGLE_VERIFICATION_TASK_PICKER_MODAL',
  'TOGGLE_EDIT_TASK_MODAL',
  'TOGGLE_FILE_UPLOAD_MODAL',
  'TOGGLE_DELETE_TASK_MODAL',
  'COMPLETE_TASK_LOADING',
  'INCOMPLETE_TASK_LOADING',
  'TOGGLE_CONNECT_ACCOUNT_MODAL',
  'SET_PREVIEW_CURRENT_DOCUMENT',
  'SET_PREVIEW',
  'RESET_PREVIEW',
  'ADD_SELECTED_ATTACMENT',
  'REMOVE_SELECTED_ATTACMENT',
  'RESET_ATTACHMENTS',
  'SET_FILE_SELECTOR_ACTIVE_TASK',
  'SET_FILE_PREVIEW_CURRENT_DOCUMENT',
  'SET_FILE_PREVIEW_ERROR',
  'SET_FILE_PREVIEW',
  'RESET_FILE_PREVIEW',
  'SET_FILE_PREVIEW_EMBED_URL',
  'SET_V2_DOWNLOADS',
  'SET_V2_DOWNLOAD_METADATA',
  'ADD_V2_DOWNLOAD',
  'SET_V2_DOWNLOAD_LOADING',
  'SET_ATTACHMENT_LOADING',
  'SET_ATTACHMENT_SAVING',
  'ADD_DOCUMENT_TO_SELECTED_TASK',
  'SET_SIGNABLE_DOCUMENTS',
  'UPDATE_SIGNABLE_DOCUMENT',
  'DESTROY_SIGNABLE_DOCUMENT',
  'SHOW_SIGNABLE_DOCUMENT_CREATE_MODAL',
  'HIDE_SIGNABLE_DOCUMENT_CREATE_MODAL',
  'SHOW_SIGNABLE_DOCUMENT_EDIT_MODAL',
  'HIDE_SIGNABLE_DOCUMENT_EDIT_MODAL',
  'SHOW_SIGNABLE_DOCUMENT_RESEND_MODAL',
  'HIDE_SIGNABLE_DOCUMENT_RESEND_MODAL',
  'HIDE_SIGNABLE_DOCUMENT_DESTROY_MODAL',
  'SHOW_SIGNABLE_DOCUMENT_DESTROY_MODAL',
  'TOGGLE_NEW_CLIENT_MODAL',
  'TOGGLE_EDIT_LOAN_FILE_MODAL',
  'SET_LOAN_FILE_TEMPLATES',
  'SET_COMMUNICATIONS',
  'ADD_COMMUNICATION',
  'REMOVE_COMMUNICATION',
  'ADD_TASK_TEMPLATE',
  'EDIT_TASK_TEMPLATE',
  'REMOVE_TASK_TEMPLATE',
  'SET_DOCUMENT_GROUP_TEMPLATE',
  'MOVE_UP_DOCUMENT_GROUP_TEMPLATE',
  'MOVE_DOWN_DOCUMENT_GROUP_TEMPLATE',
  'REMOVE_ID_FROM_DOCUMENT_GROUP_TEMPLATE_ORDER_LIST',
  'ADD_ID_TO_DOCUMENT_GROUP_TEMPLATE_ORDER_LIST',
  'SET_DOCUMENT_GROUP_TEMPLATE_ERROR',
  'TOGGLE_ATTACHMENT_MODAL',
  'TOGGLE_ATTACHMENT_PREVIEW_MODAL',
  'SET_SELECTED_ATTACHMENT',
  'SET_ATTACHMENT_PREVIEW_ERROR',
  'SET_ATTACHMENT_PREVIEW_EMBED_URL',
  'SET_ATTACHMENT_PREVIEW_LOADING',
  'SET_NEW_CLIENT_STEP',
  'SET_SELECTED_TASK_TEMPLATES',
  'SHOW_TASK_TEMPLATE_FORM_MODAL',
  'SHOW_TASK_TEMPLATE_DESTROY_MODAL',
  'SHOW_TASK_TEMPLATE_CLONE_MODAL',
  'SET_STAGED_DOCUMENT_GROUP_TEMPLATE',
  'UPDATE_STAGED_DOCUMENT_GROUP_TEMPLATE',
  'REMOVE_ACCOUNT',
  'SET_VERIFICATION',
  'SET_VERIFICATIONS',
  'SET_VERIFICATION_LOADING',
  'TOGGLE_TASK_TEMPLATE_PICKER_MODAL',
  'SET_VERIFICATION_TASKS',
  'ADD_VERIFICATION_TASK',
  'EDIT_VERIFICATION_TASK',
  'DESTROY_VERIFICATION_TASK',
  'SET_STAGED_VERIFICATION_TASK',
  'UPDATE_STAGED_VERIFICATION_TASK',
  'SHOW_CREATE_VERIFICATION_TASK_MODAL',
  'SHOW_DESTROY_VERIFICATION_TASK_MODAL',
  'UPDATE_STAGED_VERIFICATION_TASK_SUBMISSION',
  'TOGGLE_TASK_TYPE_PICKER_MODAL',
  'SET_LOAN_APP_TEMPLATES',
  'ADD_LOAN_APP_TEMPLATE',
  'EDIT_LOAN_APP_TEMPLATE',
  'DESTROY_LOAN_APP_TEMPLATE',
  'SHOW_CREATE_LOAN_APP_TEMPLATE_MODAL',
  'SHOW_DESTROY_LOAN_APP_TEMPLATE_MODAL',
  'TOGGLE_DESTROY_LOAN_APP_TEMPLATE_MODAL_SUBMITTING',
  'LOAN_APP_TEMPLATES_LOADING',
  'SET_STAGED_LOAN_APP_TEMPLATE',
  'TOGGLE_CREATE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING',
  'SHOW_UPDATE_LOAN_APP_TEMPLATE_MODAL',
  'UPDATE_STAGED_LOAN_APP_TEMPLATE',
  'TOGGLE_UPDATE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING',
  'SHOW_CLONE_LOAN_APP_TEMPLATE_MODAL',
  'CLONE_STAGED_LOAN_APP_TEMPLATE',
  'TOGGLE_CLONE_LOAN_APP_TEMPLATE_MODAL_SUBMITTING',
  'SET_LOAN_APPS',
  'ADD_LOAN_APP',
  'ADD_LOAN_APPS',
  'EDIT_LOAN_APP',
  'DESTROY_LOAN_APP',
  'SHOW_EDIT_LOAN_APP_MODAL',
  'SHOW_CREATE_LOAN_APP_MODAL',
  'SHOW_DESTROY_LOAN_APP_MODAL',
  'TOGGLE_LOAN_APP_LOADING_TEXT',
  'LOAN_APP_LOADING',
  'LOAN_APP_TEMPLATE_LOADING',
  'SET_STAGED_LOAN_APP',
  'UPDATE_STAGED_LOAN_APP',
  'SET_LOAN_APP_TEMPLATE_SECTIONS',
  'ADD_LOAN_APP_TEMPLATE_SECTION',
  'EDIT_LOAN_APP_TEMPLATE_SECTION',
  'DESTROY_LOAN_APP_TEMPLATE_SECTION',
  'SHOW_CREATE_LOAN_APP_TEMPLATE_SECTION_MODAL',
  'SHOW_DESTROY_LOAN_APP_TEMPLATE_SECTION_MODAL',
  'LOAN_APP_TEMPLATE_SECTIONS_LOADING',
  'SET_STAGED_LOAN_APP_TEMPLATE_SECTION',
  'UPDATE_STAGED_LOAN_APP_TEMPLATE_SECTION',
  'SET_LOAN_APP_TEMPLATE_QUESTIONS',
  'ADD_LOAN_APP_TEMPLATE_QUESTION',
  'EDIT_LOAN_APP_TEMPLATE_QUESTION',
  'DESTROY_LOAN_APP_TEMPLATE_QUESTION',
  'SHOW_CREATE_LOAN_APP_TEMPLATE_QUESTION_MODAL',
  'SHOW_EDIT_LOAN_APP_TEMPLATE_QUESTION_MODAL',
  'SHOW_DESTROY_LOAN_APP_TEMPLATE_QUESTION_MODAL',
  'LOAN_APP_TEMPLATE_QUESTIONS_LOADING',
  'SET_STAGED_LOAN_APP_TEMPLATE_QUESTION',
  'UPDATE_STAGED_LOAN_APP_TEMPLATE_QUESTION',
  'SET_CURRENT_LOAN_APP_TEMPLATE_SECTION',
  'SET_LOAN_APP_RESPONSES',
  'UPDATE_LOAN_APP_RESPONSE_VALUE',
  'UPDATE_LOAN_APP_RESPONSE_ERRORS',
  'SET_LOAN_APP_RESPONSE_ERRORS',
  'SHOW_FINISH_SUBMISSION_LOAN_APP_MODAL',
  'SHOW_LENDER_TRID_MODAL',
  'LOAN_APP_RESPONSE_SET_SYNC_TIME',
  'TOGGLE_OVERLAY_SPINNER',
  'SET_LOAN_APP_ERROR_COUNT',
  'SET_LOAN_APP_DISABLED',
  'TOGGLE_LOAN_APP_SUBMIT_BUTTON',
  'SET_LENDING_QB_DOCUMENTS',
  'SET_LENDING_QB_DOCUMENTS_METADATA',
  'SET_LENDING_QB_DOCUMENT',
  'SET_LOAN_FILE_PARTICIPANTS',
  'SET_TEAM_ROLES',
  'ADD_LOAN_FILE_PARTICIPANT',
  'SET_PARTICIPANTS_TO_ADD',
  'REMOVE_LOAN_FILE_PARTICIPANT',
  'CLEAR_LOAN_FILE_PARTICIPANTS',
  'TOGGLE_TEAM_PICKER_MODAL',
  'TOGGLE_PRE_APPROVAL_LETTER_TEMPLATE_MODAL',
  'SET_STAGED_CREDIT_TASK',
  'SET_CREDIT_TASK_LOADING',
  'SET_CREDIT_TASK_DELETING',
  'SET_CREDIT_TASKS',
  'NEXT_CREDIT_TASK_POSITION',
  'SET_CREDIT_REPORTEES_LOADING',
  'SET_CREDIT_REPORTEES',
  'SET_STAGED_CREDIT_REPORTEE',
  'TOGGLE_TASK_NOTIFICATION_EMAIL_MODAL',
  'SET_TASK_NOTIFICATIONS_FREQUENCY',
  'SET_TASK_NOTIFICATIONS_CHECKED',
  'SET_TASK_NOTIFICATIONS_LOADING',
  'SET_TASK_NOTIFICATIONS_SENDING',
  'SET_LOAN_FILE_TASK_REPLY_TO',
  'SET_LOAN_FILE_TASK_REPLY_TO_CC',
  'SET_CLOSINGS_TASK',
  'SET_COMMUNICATIONS_LOADING',
  'TOGGLE_CREDIT_REPORT_MODAL',
  'TOGGLE_NLP_LOADING',
  'SET_MOCK_COMMUNICATION',
  'REMOVE_MOCK_COMMUNICATION',
  'SET_TEAMS',
  'SET_STAGED_TEAM',
  'SET_TEAM_LOAN_FILES',
  'VALIDATING_LOAN_NUMBER_WITH_LENDING_QB',
  'TOGGLE_LENDING_QB_NUMBER_EDIT_MODAL',
  'TOGGLE_ENCOMPASS_NUMBER_IMPORT_MODAL',
  'IMPORTING_LOAN_NUMBER_FROM_ENCOMPASS',
  'TOGGLE_ENCOMPASS_RESYNC_MODAL',
  'LOAN_APP_SYNCING_RESPONSE',
  'SET_STAGED_EMAIL_TEMPLATE',
  'UPDATE_STAGED_EMAIL_TEMPLATE',
  'TOGGLE_EMAIL_TEMPLATE_MODAL',
  'SET_BORROWER_EMAIL_TEMPLATES',
  'SET_LENDER_EMAIL_TEMPLATES',
  'SET_REALTOR_EMAIL_TEMPLATES',
  'SET_CURRENT_SORT_BY',
  'TOGGLE_NEW_LOAN_FILE_TEMPLATE_MODAL',
  'SET_LOAN_FILE_TEMPLATES',
  'SET_MASTER_LOAN_FILE_TEMPLATES',
  'TOGGLE_MASTER_LOAN_FILE_TEMPLATE_CLONE_MODAL',
  'TOGGLE_MASTER_LOAN_FILE_TEMPLATE_NEW_MODAL',
  'TOGGLE_MASTER_LOAN_FILE_TEMPLATE_DESTROY_MODAL',
  'SET_STAGED_MASTER_LOAN_FILE_TEMPLATE',
  'UPDATE_STAGED_MASTER_LOAN_FILE_TEMPLATE',
  'ADD_MASTER_LOAN_FILE_TEMPLATE',
  'REMOVE_MASTER_LOAN_FILE_TEMPLATE',
  'SET_ORGANIZATION',
  'UPDATE_ORGANIZATION',
  'SET_FILTERED_LOAN_FILES',
  'SET_CURRENT_EMAIL_SIGNATURE_TEMPLATE',
  'SET_DEPLOYED_EMAIL_SIGNATURE_TEMPLATE',
  'TOGGLE_EMAIL_SIGNATURE_EDIT_MODAL',
  'TOGGLE_EMAIL_SIGNATURE_CONFIRMATION_MODAL',
  'TOGGLE_EMAIL_TEMPLATES_CHANGE_CONFIRMATION_MODAL',
  'SUBMITTING_EMAIL_CHANGES',
  'UPDATING_DOCUMENT',
  'SET_STAGED_TEXT_MESSSAGE_SETTINGS',
  'SET_CURRENT_TEXT_MESSSAGE_SETTINGS',
  'UPDATE_STAGED_TEXT_MESSSAGE_SETTINGS',
  'TOGGLE_TEXT_MESSAGE_CHANGE_CONFIRMATION_MODAL',
  'TOGGLE_TEXT_MESSAGE_CHANGES',
  'SET_LENDING_QB_AUDIT_LOG',
  'TOGGLE_LENDING_QB_AUDIT_MODAL',
  'SET_STAGED_LENDER',
  'SET_PROPERTY_ADDRESS',
  'SET_QUOTE_REQUESTS',
  'SET_QUOTE_REQUEST',
  'SET_DEFAULT_SORT_BY',
  'TOGGLE_DOCUMENT_CHANGED',
  'TOGGLE_DOCUMENT_SAVING',
  'TOGGLE_PDF_TRON_LOADED',
  'TOGGLE_PDF_TRON_MERGING',
  'SET_OPENED_DOCUMENT_GROUPS',
  'SET_PDF_TRON_CURRENT_DOCUMENT',
  'SET_PDF_TRON_CORE_CONTROLS',
  'SET_PDF_TRON_VIEWER_INSTANCE',
  'TOGGLE_SHOW_CROP',
  'SET_DOCUMENT_CROPPING_BOUNDS',
  'SET_DOCUMENT_CROPPING_SELECTION',
  'SET_DOCUMENT_CURRENT_PAGE_NUMBER',
  'SET_DOCUMENT_CURRENT_PAGE_ZOOM',
  'TOGGLE_PDF_TRON_REORDER_FORM',
  'TOGGLE_PDF_TRON_DELETE_FORM',
  'TOGGLE_PDF_TRON_MERGE_MODAL',
  'SET_DOCUMENT_SORT_DATA',
  'ADD_LOAN_NOTES_BOX_DOCUMENT',
  'SET_LOAN_NOTES_BOX_DOCUMENTS',
  'UPDATE_PREFILL_RESPONSES',
  'SET_FINICITY_CONNECT_URL',
  'SET_FINICITY_CONNECT_URL_LOADING',
  'SET_FINICITY_CONNECT_FIX_URL',
  'SET_FINICITY_CONNECT_FIX_URL_LOADING',
  'SET_FINICITY_PENDING_STATUS',
  'SET_FINICITY_IFRAME_VISIBLE',
  'SET_IMPORT_STATEMENTS_STATUS',
  'TOGGLE_TWO_FACTOR_CANCELLING',
  'TOGGLE_TWO_FACTOR_UPDATING',
  'TOGGLE_MOBILE_PHONE_VERIFIED',
  'TOGGLE_TWO_FACTOR_CODE_REQUIRED',
  'TOGGLE_UPDATE_PHONE',
  'SET_TWO_FACTOR_CODE',
  'SET_TWO_FACTOR_ENABLED',
  'SET_TWO_FACTOR_PHONE',
  'ADD_FLASH_ALERT',
  'POP_ALERT',
  'RESET_ALERTS',
  'POP_TOAST',
  'RESET_TOASTS',
  'SET_ONBOARDING_STEP',
  'SET_ONBOARDING_UPDATING',
  'SET_ONBOARDING_CANCELLING',
  'SET_PROFILE_VALUES',
  'SET_MESSENGER_VIEWABLE_STATE',
  'RESET_UNREAD_MESSAGES_COUNT',
  'SET_USER_INFO',
  'SET_LOAN_FILE_ID',
])
