import { DEFAULT_DASHBOARD_METADATA } from './DefaultDashboardMetadata'

export const PROCESSOR_DASHBOARD_METADATA = DEFAULT_DASHBOARD_METADATA.map((element) => {
  switch (element.sortKey) {
    case 'realtorName': {
      return {
        ...element,
        disabled: true,
      }
    }
    case 'loanOfficerName': {
      return {
        ...element,
        disabled: false,
      }
    }
    default: {
      return { ...element }
    }
  }
})
