import { words, capitalize } from 'underscore.string'

export function delimitNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function lastWord(record, key) {
  return capitalize(words(record[key]).slice(-1))
}

export function pxToInteger(pxString) {
  return parseInt(pxString.replace('px', ''), 10)
}

export function integerToPx(integer) {
  return integer.toString() + 'px'
}

export function pluralize(count, singular, plural) {
  if (count === 1) {
    return singular
  } else {
    return plural
  }
}

// Attempts to split a name into its corresponding parts. This is not clever. It will break on
// anything but the most basic cases. See spec for examples.
// This is the frontend complement to `ParsedName` in Ruby.
export function splitName(fullName) {
  if (!fullName) {
    return { first: '', middle: '', last: '' }
  }

  // Check if only one name given and short-circuit out
  if (fullName.trim().indexOf(' ') === -1) {
    return { first: fullName, middle: '', last: '' }
  }

  const firstName = fullName.match(/^[^ ]*/)[0]
  const middleNameMatch = fullName.match(/ ([^ ]*)(?= )/)
  const middleName = middleNameMatch ? middleNameMatch[0].trim() : ''
  const lastName = middleName
    ? fullName.substring(middleNameMatch.index + 1 + middleName.length + 1)
    : fullName.substring(fullName.indexOf(' ') + 1)

  return {
    first: firstName,
    middle: middleName,
    last: lastName,
    preferred_first: firstName,
    preferred_middle: middleName,
    preferred_last: lastName,
  }
}

export function displayName({ firstName, middleName, lastName, suffix }) {
  let displayNameReturn = firstName
  displayNameReturn += middleName ? ' ' + middleName : ''
  displayNameReturn += lastName ? ' ' + lastName : ''
  displayNameReturn += suffix ? ', ' + suffix : ''
  return displayNameReturn
}
