let cancelCallback = null

const sleep = (period) => {
  return new Promise((resolve) => {
    cancelCallback = () => resolve('Canceled')
    setTimeout(() => resolve('tick'), period)
  })
}

export const pollCondition = (period, conditionFn, completeFn, timeout) => {
  if (cancelCallback === null) setTimeout(() => cancelCallback(), timeout)

  sleep(period).then((respond) => {
    if (conditionFn()) completeFn()
    else if (respond !== 'Canceled') pollCondition(period, conditionFn, completeFn)
  })
}
