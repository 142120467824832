/* eslint-disable max-len */
import { mapValues } from 'lodash'

const lender = {
  index: '/loans',
  root: '/lenders/:lenderId',
}

const borrower = { index: '/borrowers/:borrowerId' }

const borrowerDashboard = {
  mainIndex: '/borrower_dashboard/:borrowerId',
  index: '/borrower_dashboard/:borrowerId/loan_files/:loanFileId',
  tasks: {
    index: '/borrower_dashboard/:borrowerId/loan_files/:loanFileId/tasks',
    submitted: '/borrower_dashboard/:borrowerId/loan_files/:loanFileId/tasks/submitted',
    completed: '/borrower_dashboard/:borrowerId/loan_files/:loanFileId/tasks/completed',
    show: '/borrower_dashboard/:borrowerId/loan_files/:loanFileId/tasks/:taskId',
  },
  documents: '/borrower_dashboard/:borrowerId/loan_files/:loanFileId/documents',
  team: '/borrower_dashboard/:borrowerId/loan_files/:loanFileId/team',
  settings: '/borrower_dashboard/:borrowerId/settings',
}

const publicRoutes = {
  landing: {
    index: '/home/:lenderId',
    apply: {
      index: '/apply/:lenderId',
      purpose: '/apply/:lenderId/purpose',
      account: '/apply/:lenderId/account',
      template: '/apply/:lenderId/:templateSlug',
    },
    custom_consent: '/consent',
    credit_auth: '/credit_authorization',
    e_signatures_consent: '/e_signatures_consent',
    privacy: '/privacy',
    terms: '/terms',
    terms_business_intelligence: '/terms/:agreementType',
    coborrower: '/users/confirmation',
  },
  loanApplications: {
    borrowers: '/loan_applications/:loanAppId/borrowers',
    index: '/loan_applications',
    review: '/loan_applications/:loanAppId/review',
    sections: { show: '/loan_applications/:loanAppId/:template/:sectionSlug' },
    security: '/loan_applications/:loanAppId/security',
    submitted: '/loan_applications/:loanAppId/submitted',
  },
  mortgageCalculator: '/mortgage_calculator',
  quickApply: {
    index: '/loan_applications/:loanAppId/quick_apply',
    authenticate: '/loan_applications/:loanAppId/quick_apply/authenticate',
    otp: '/loan_applications/:loanAppId/quick_apply/otp',
    review: '/loan_applications/:loanAppId/quick_apply/review',
    bankAssets: '/loan_applications/:loanAppId/quick_apply/bank_assets',
  },
}

const loanFile = {
  index: `${lender.root}/loan_files/:loanFileId`,
  multi: `${lender.root}/loan_files/:loanFileId/multi`,
  multiPage: `${lender.root}/loan_files/:loanFileId/multi/:loanFileTab`,
  quickPricer: {
    index: `${lender.root}/loan_files/:loanFileId/quick_pricer`,
    scenarios: { index: `${lender.root}/loan_files/:loanFileId/quick_pricer/scenarios` },
    shares: { show: `${lender.root}/loan_files/:loanFileId/quick_pricer/shares/:shareId` },
  },
  disclosuresTaskItems: { show: `${lender.root}/loan_files/:loanFileId/disclosures_tasks/:disclosuresTaskId/disclosures_task_items/:disclosuresTaskItemId` },
  creditTasks: { show: `${lender.root}/loan_files/:loanFileId/credit_tasks/:creditTaskId` },
}

const aus = {
  caseFiles: { new: `${loanFile.index}/aus/casefiles/new` },
  scenarios: {
    index: `${loanFile.index}/aus/scenarios`,
    show: `${loanFile.index}/aus/scenarios/:scenarioId/sections`,
    scenarioBuilder: `${loanFile.index}/aus/scenarios/:scenarioId/sections/:sectionId`,
    mismo: `${loanFile.index}/aus/scenarios/:scenarioId/mismo?download=true`,
    decisionFullFeedback: `${loanFile.index}/aus/scenarios/:scenarioId/underwriting_decisions/:decisionId/full_feedback`,
    loanApplications: mapValues(publicRoutes.loanApplications, loanAppRoute => (typeof loanAppRoute !== 'object'
      ? `${loanFile.index}/aus/scenarios/:scenarioId${loanAppRoute}`
      : mapValues(
        loanAppRoute,
        subRoutes => `${loanFile.index}/aus/scenarios/:scenarioId${subRoutes}`
      ))),
  },
}
const attachments = { show: `${loanFile.index}/attachments/:attachmentId` }

const orgReporting = {
  loanApplicationReport: `${lender.root}/org_reporting/loan_application_report`,
  loanVolumeReport: `${lender.root}/org_reporting/loan_volume_report`,
}

const settings = {
  losSettings: `${lender.root}/settings/los_settings`,
  index: `${lender.root}/settings`,
  mpay: `${lender.root}/settings/mpay`,
  organization: `${lender.root}/settings/organization`,
  organizationIntegrations: `${lender.root}/settings/organization_integrations`,
  paymentsHistory: `${lender.root}/settings/payments_history`,
  pipeline: `${lender.root}/settings/pipeline`,
  pipelines: `${lender.root}/settings/pipelines`,
  profile: `${lender.root}/settings/profile`,
  personalIntegrations: `${lender.root}/settings/personal_integrations`,
  templates: `${lender.root}/settings/org_templates`,
  templateEdit: `${lender.root}/settings/org_templates/:templateId`,
  templatePreview: `${lender.root}/settings/org_templates/:templateId/sections/:sectionId/preview`,
  templateEditSection: `${lender.root}/settings/org_templates/:templateId/sections/:sectionId`,
  users: `${lender.root}/settings/users`,
}

const quickPricer = {
  new: `${lender.root}/quick_pricer/new`,
  shares: { show: '/quick_pricer/shares/:shareId' },
}

const loans = {
  index: '/loans',
  new: '/loans/new',
}

const businessIntelligence = {
  index: '/business_intelligence/:categorySlug?/:subcategorySlug?/:itemSlug?',
  reportCategory: ['/business_intelligence/:categorySlug', '/business_intelligence'],
  reportItem: '/business_intelligence/:categorySlug/:subcategorySlug/:itemSlug',
  askMax: '/business_intelligence/askmax',
}

const screenShare = { show: `${lender.root}/loan_files/:loanFileId/screen_share` }

export default {
  attachments,
  aus,
  borrower,
  borrowerDashboard,
  lender,
  loanFile,
  loans,
  businessIntelligence,
  orgReporting,
  publicRoutes,
  quickPricer,
  screenShare,
  settings,
}
