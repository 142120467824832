import { camelize } from 'underscore.string'
import { lastWord } from 'utils/string'

export const DEFAULT_DASHBOARD_METADATA = [
  {
    sortKey: 'borrowerLastName',
    sortable: true,
    label: 'Borrower',
    sortCriteria: lastWord,
  },
  {
    sortKey: 'receivedDocumentsCount',
    sortable: true,
    label: 'To Review',
    default: 'desc',
  },
  {
    sortKey: 'completedTaskCount',
    sortable: true,
    label: 'Tasks',
  },
  {
    sortKey: 'createdAt',
    sortable: true,
    label: 'Created',
  },
  {
    sortKey: 'realtorName',
    sortable: true,
    label: 'Agent',
    sortCriteria: lastWord,
  },
  {
    sortKey: 'loanOfficerName',
    sortable: true,
    label: 'Loan Officer',
    sortCriteria: lastWord,
    disabled: true,
  },
  {
    sortKey: 'loanFileStatus',
    sortable: true,
    label: 'Status',
    sortCriteria: (record, key) => {
      return record.statuses[camelize(record[key])]
    },
  },
  {
    sortKey: 'borrowerLastSignInAt',
    sortable: true,
    label: 'Last logged in',
    sortCriteria: ((record, key) => record[key] || '000000000'),
  },
  {
    sortKey: '',
    label: 'Messages',
  },
  {
    sortKey: '',
    label: 'LoanNotes',
  },
]

export const LOAN_FILES_PAGE_SIZE = 50
