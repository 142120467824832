import toastr from 'utils/toastr'

import {
  capitalize,
  underscored,
} from 'underscore.string'

import {
  isArray,
  isObject,
  flatten,
} from 'underscore'

import { APP_CONSTANTS } from '../constants'

// TODO : Extend for use with full_messages ['email is crap', 'name is wrong'] etc.
// For use with Active::Record errors
// { errors: { attribute: ["error message"] } }

// Handles the following error structures - these are the Rails format

// Base Errors - Single Message errors.add(:base, "ex. error")
// ex. {:base=>[{:realtor_id=>["Realtor already associated with this loan file"]}]}
// Handled in errorsInBaseFormat

// Base Errors - Multiple Messages
// ex {:base=>[{:realtor_id=>["Realtor already associated with this loan file", "The realtor must have a brain"]}]}
// Handled in errorsInBaseFormat

// General Model Errors (errors.messages)
// ex. {:boring_reason=>["can't be blank when rejecting document."]}
// Handled in errorsInModelFormat

// General Model Errors (errors.full_messages)
// ex. ["Name can't be blank", "Brain can't be blank"]
// Handled in errorsInArrayFormat

// General Model Errors - Multiple Validations Fail (errors.messages)
// ex. [{:realtor_id=>["already associated with this loan file"]},
//     {:realtor_name=>["already associated with this loan file"]}]
// Handled in errorsInArrayOfObjectsFormat

// Empty Object Errors
// ex. {}
// Handle in errorsInModelFormat - returns static text "Something went wrong. Please try again."

// String Errors
// ex. "I am an error!!"
// handled in toastrErrors

export function errorMessage(error) {

  return error.res.error.message ? error.res.error.message : APP_CONSTANTS.ERROR_TEXT
}

export function toastrErrors(errors) {
  if (typeof errors === 'string' && process.env.NODE_ENV !== 'production') {
    toastr.error(errors)
    return
  }

  if (typeof errors === 'string') {
    toastr.error(errors)
    return
  }

  if (isArray(errors)) {
    return determineWhatKindOfArrayErrors(errors)
  }

  if (isObject(errors)) {
    return determineWhatKindOfObjectErrors(errors)
  }
}

function determineWhatKindOfArrayErrors(errors) {
  if (errors.length === 0) return

  if (isObject(errors[0])) {
    return errorsInArrayOfObjectsFormat(errors)
  } else {
    return errorsInArrayFormat(errors)
  }
}

function determineWhatKindOfObjectErrors(errors) {
  if (errors.base) {
    return errorsInBaseFormat(errors)
  } else if (errors.errors) {
    return errorsInModelFormat(errors.errors[0])
  } else if (errors.message) {
    return toastr.error(errors.message)
  } else {
    return errorsInModelFormat(errors)
  }
}

function errorsInArrayFormat(errors) {
  errors.forEach((error) => {
    toastr.error(error)
  })
}

function errorsInArrayOfObjectsFormat(errors) {
  errors.forEach((obj) => {
    const key = Object.keys(obj)[0]
    const value = Object.values(obj)[0]
    const errorString = formatText(key, value)
    toastr.error(errorString)
  })
}

// TODO : Model formatted errors are not strings?
function errorsInModelFormat(errors) {
  if (typeof errors === 'string') {
    return toastr.error(errors)
  }


  const keys = Object.keys(errors)
  const values = Object.values(errors)

  if (!(keys.length && values.length)) toastr.error(APP_CONSTANTS.ERROR_TEXT)
  keys.forEach((key, index) => {
    values[index].forEach((value) => {
      const errorString = formatText(key, value)
      toastr.error(errorString)
    })
  })
}

function errorsInBaseFormat(errors) {
  errors.base.forEach((obj) => {
    const arrayOfErrors = flatten(Object.values(obj))
    arrayOfErrors.forEach((error) => {
      toastr.error(error)
    })
  })
}

function formatText(key, value) {
  const attr = capitalize(underscored(key).split('_').join(' '))
  const errorString = `${attr} ${value}`
  return errorString
}
