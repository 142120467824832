import { snakeCase, flow, toUpper } from 'lodash'

const defaultActionTypes = [
  'set',
  'create',
  'update',
  'destroy',
  'loading',
  'setStagedRecord',
  'updateStagedRecord',
  'showCreateModal',
  'showUpdateModal',
  'creatingRecord',
  'updatingRecord',
]

const formatActionTypeString = flow(snakeCase, toUpper)

// pass array in `only` if you want to just create few actionTypes.
// ex: createActionTypes('lender', { only: ['set'] })
// pass array in `also` if you want add support some more actionTypes.
// ex: createActionTypes('lender', { also: ['showDestroyModal'] })
export const createActionTypes = (resourceName, { only, also } = {}) => {
  let types = defaultActionTypes
  if (only)
    types = only
  if (also)
    types = types.concat(also)
  const actionTypes = {}
  types.map(type => actionTypes[type] = formatActionTypeString(`${resourceName}.${type}`))
  return actionTypes
}
